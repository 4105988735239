import { useEffect, useState } from "react";
import { useLocation, BrowserRouter, Route } from "react-router-dom";
import { ThemeProvider } from "@fluentui/react";
import { CoherenceCustomizations } from "@cseo/styles";
import Header from "./components/common/Header";
import Nav from "./components/common/Nav";
import Container from "./components/common/Container";
import { PMUser } from "./constants";
import { TelemetryService, telemetryConfig } from "./service/telemetry";
import { IPageViewTelemetry } from "@microsoft/applicationinsights-web";
import { getUserDisplayName, getUserName, isInRole, authenticationParameters } from "./service/authProvider";
import { MsalAuthenticationTemplate, MsalProvider, useIsAuthenticated } from "@azure/msal-react";
import ElmRoutes from "./ElmRoutes";
import StandardRoutes from "./StandardRoutes";
import {
    AuthenticationResult,
    EventMessage,
    EventType,
    InteractionType,
    IPublicClientApplication
} from "@azure/msal-browser";
import store from "./store";
import { Provider } from "react-redux";
import UnauthorizedPage from "./pages/Unauthorized";
import { useAppSelector } from "./hooks/ReduxHooks";

import { selectNotifications } from "./reducers/NotificationsReducer";

function Pages() {
    const loc = useLocation();
    const isAuthenticated = useIsAuthenticated();
    const [isPm, setIsPm] = useState(false);
    const [isNavCollapsed, setIsNavCollapsed] = useState(false);
    const { notifications } = useAppSelector(selectNotifications);
    function onNavCollapsed(_isNavCollapsed: boolean) {
        setIsNavCollapsed(_isNavCollapsed);
    }
    const pathToLabelMapping = {
        "": "Home",
        datafactories: "Data Factories",
        editDataFactory: "Edit Data Factory",
        pipelines: "Pipelines",
        editPipeline: "Edit Pipeline",
        editFeed: "Edit Feed",
        feedFreshnessTrends: "Feed Freshness Trends",
        feedFreshness: "Feed Freshness",
        feedProfile: "Feed Profile",
        feeds: "Feeds",
        feedcategories: "Feed Categories",
        editFeedCategory: "Edit Feed Category",
        contacts: "Contacts",
        directoryAudit: "Directory Audit",
        azureResources: "Azure Resources",
        unauthorized: "Unauthorized",
        adlsCapacityTrend: "Adls Capacity Trend",
        powerBIReports: "PowerBIReports",
        addReport: "Add Report",
        accessPackages: "Access Packages",
        tagInternalObjects: "Internal Object Tagging",
    };

    function GetPageAriaLabel() {
        const loc = useLocation();

        const path = loc.pathname.split("/");
        return pathToLabelMapping[path[1]];
    }
    useEffect(() => {
        if (isAuthenticated) {
            setIsPm(isInRole(PMUser));
        }
    }, [isAuthenticated]);

    // Method to track page view activity
    useEffect(() => {
        TelemetryService.initialize(telemetryConfig);
        // Ensure only site page visit is logged and skipping url with refresh token and error details
        if (window.location.href.indexOf("#") === -1) {
            const pageInfo: IPageViewTelemetry = {
                name: document ? document.title : "",
                uri: window.location.href.toLowerCase(),
                properties: { user_Id: getUserName(), user_DisplayName: getUserDisplayName() }
            };
            TelemetryService.trackPageView(pageInfo);
        }
    }, [loc.pathname]);
    return (
        <>
            <Header notificationItems={notifications} />
            <Nav onNavCollapsed={onNavCollapsed}></Nav>
            <Container isNavCollapsed={isNavCollapsed}>
                <main id="main" tabIndex={-1} aria-label={GetPageAriaLabel()}>
                    <StandardRoutes />

                    {isPm && <ElmRoutes />}
                    <Route exact path="/unauthorized" component={UnauthorizedPage} />
                </main>
            </Container>
        </>
    );
}
function App({ msalInstance }: { msalInstance: IPublicClientApplication }) {
    msalInstance.handleRedirectPromise().then((result) => {
        if (result) {
            msalInstance.setActiveAccount(result.account);
        }
        return result;
    });
    msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
        }
    });
    return (
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
                authenticationRequest={authenticationParameters}
                interactionType={InteractionType.Redirect}
            >
                <ThemeProvider {...CoherenceCustomizations}>
                    <Provider store={store}>
                        <BrowserRouter>
                            <Pages />
                        </BrowserRouter>
                    </Provider>
                </ThemeProvider>
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
}

export default App;
